export class SegmentFilterType {
    static CONSENT: SegmentFilterType = new SegmentFilterType("CONSENT");
    static NONE: SegmentFilterType = new SegmentFilterType("NONE");
    static SEGMENT: SegmentFilterType = new SegmentFilterType("SEGMENT");
    static SEGMENT_TYPE: SegmentFilterType = new SegmentFilterType("SEGMENT_TYPE"); // deprecated
    static SEGMENT_WITH_CONSENT: SegmentFilterType = new SegmentFilterType("SEGMENT_WITH_CONSENT");

    name: string;

    constructor(name: string) {
        this.name = name;
    }

    static values(): SegmentFilterType[] {
        return [
            SegmentFilterType.SEGMENT,
            SegmentFilterType.CONSENT,
            SegmentFilterType.SEGMENT_WITH_CONSENT,
            SegmentFilterType.NONE
        ];
    }
}
