import {CommonApiSession} from "../common/api/CommonApiSession";
import {RestStorage} from "./rest/RestStorage";
import {RestSegment} from "./rest/RestSegment";
import {RestStorageFilter} from "./rest/RestStorageFilter";
import {RestOrder} from "./rest/RestOrder";
import {RestPricing} from "./rest/RestPricing";
import {PricingDetails} from "./model/partner/pricing/PricingDetails";
import {RestVendor} from "./rest/RestVendor";
import {VendorList} from "./model/vendor/VendorList";

export class ApiSession extends CommonApiSession {
    private _restSegment: RestSegment | undefined;
    private _restStorage: RestStorage | undefined;
    private _restStorageFilter: RestStorageFilter | undefined;
    private _restOrder: RestOrder | undefined;
    private _restPricing: RestPricing | undefined;
    private _restVendor: RestVendor | undefined;

    private _pricingDetails: PricingDetails | undefined;
    private _vendorLists: VendorList[] | undefined;

    get restSegment(): RestSegment {
        return this._restSegment !== undefined ? this._restSegment : this._restSegment = new RestSegment(this._commonClient);
    }

    get restStorage(): RestStorage {
        return this._restStorage !== undefined ? this._restStorage : this._restStorage = new RestStorage(this._commonClient);
    }

    get restStorageFilter(): RestStorageFilter {
        return this._restStorageFilter !== undefined ? this._restStorageFilter : this._restStorageFilter = new RestStorageFilter(this._commonClient);
    }

    get restOrder(): RestOrder {
        return this._restOrder !== undefined ? this._restOrder : this._restOrder = new RestOrder(this._commonClient);
    }

    get restPricing(): RestPricing {
        return this._restPricing !== undefined ? this._restPricing : this._restPricing = new RestPricing(this._commonClient);
    }

    get restVendor(): RestVendor {
        return this._restVendor !== undefined ? this._restVendor : this._restVendor = new RestVendor(this._commonClient);
    }

    async getPricingDetails(): Promise<PricingDetails> {
        if (!this._pricingDetails) {
            this._pricingDetails = await session.restPricing.getPricingDetails();
        }
        return this._pricingDetails;
    }

    async getVendorLists(): Promise<VendorList[]> {
        if (!this._vendorLists) {
            this._vendorLists = await session.restVendor.getVendorLists();
        }
        return this._vendorLists;
    }
}

export const session = new ApiSession();
