import {
    Action,
    AlertSeverity,
    ButtonBack,
    ButtonSize,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    FormLayoutRows,
    Icon,
    IconTooltip,
    Loadable,
    ModalActions,
    ModalActionsAlignment,
    ModalContent,
    RadioButtons,
    SearchAutocomplete,
    SearchIds,
    SearchIdsResult,
    SelectAutocomplete
} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {TranslationPortalFile} from "../../../utils/constants";
import {StorageSegmentFilter} from "../../../api/model/storage-filter/segment/StorageSegmentFilter";
import {SegmentFilterType} from "../../../api/model/storage-filter/segment/SegmentFilterType";
import {DataType} from "../../../api/model/segment/DataType";
import {session} from "../../../api/ApiSession";
import {Segment} from "../../../api/model/segment/Segment";
import {SegmentFilterSegment} from "../../../api/model/storage-filter/segment/SegmentFilterSegment";
import {TagDataType, TagSegment} from "../../snippet";
import {audienceHome} from "../../../utils/helper";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {VendorList} from "../../../api/model/vendor/VendorList";
import {StorageConsentFilter} from "../../../api/model/storage-filter/consent/StorageConsentFilter";
import {ModalProcessCountingActionType, useModalProcessCounting} from "./context/ModalProcessCountingContext";
import {Vendor} from "../../../api/model/vendor/Vendor";
import {VendorListName} from "../../../api/model/vendor/VendorListName";

type ModalProcessCountingSegmentsFiltersProps = {
    initCounting: StorageFilter;
    onSubmit: (counting: StorageFilter) => void;
    onGoBack?: () => void;
};

type SegmentItem = {
    segment: Segment;
    limit: number | null;
};

const ModalProcessCountingSegmentsFilters: FunctionComponent<ModalProcessCountingSegmentsFiltersProps> = ({initCounting, onSubmit, onGoBack}) => {
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);
    const {processCounting, setProcessCounting} = useModalProcessCounting();
    const {segmentFilterType} = processCounting;

    const [selectedSegments, setSelectedSegments] = useState<SegmentItem[]>([]);
    const [selectedModelingSegments, setSelectedModelingSegments] = useState<SegmentItem[]>([]);

    const [segments, setSegments] = useState<Segment[]>([]);
    const [currentSegments, setCurrentSegments] = useState<Segment[]>([]);

    const [highlightedSegments, setHighlightedSegments] = useState<SegmentItem[]>();
    const [segmentIdsSearchResult, setSegmentIdsSearchResult] = useState<SearchIdsResult>();

    const [vendorList, setVendorList] = useState<VendorList>(new VendorList());
    const [selectedVendor, setSelectedVendor] = useState<Vendor>();

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const newVendorLists = await session.getVendorLists();
                const newVendorList = newVendorLists.find((it) => it.name === VendorListName.SIRDATA);
                if (newVendorList) {
                    setVendorList(newVendorList);
                }
            } catch (e) {
                console.error("Failed to load vendors", e);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const result = await session.restSegment.getSegments();
                setSegments(result.segments);
                setCurrentSegments(result.segments);

                const segmentFilter = initCounting.segment_filter;
                if (!!segmentFilter.segment.length) {
                    const newSelectedSegments: SegmentItem[] = [];
                    segmentFilter.segment.forEach(({id, limit}) => {
                        const segment = result.segments.find((it) => it.id === id);
                        if (!!segment) {
                            newSelectedSegments.push({segment: segment, limit: limit});
                        }
                    });
                    setSelectedSegments(newSelectedSegments);
                }
                if (!!segmentFilter.segment_modeling.length) {
                    const newSelectedModelingSegments: SegmentItem[] = [];
                    segmentFilter.segment_modeling.forEach(({id, limit}) => {
                        const segment = result.segments.find((it) => it.id === id);
                        if (!!segment) {
                            newSelectedModelingSegments.push({segment: segment, limit: limit});
                        }
                    });
                    setSelectedModelingSegments(newSelectedModelingSegments);
                }

                const consentFilters = initCounting.consent_filter;
                if (!!consentFilters.length) {
                    const newSelectedVendor = vendorList.vendors.find((it) => it.id === consentFilters[0].vendor_id);
                    setSelectedVendor(newSelectedVendor);
                }
            } catch (e) {
                console.error("Failed to load segments", e);
            } finally {
                setLoading(false);
            }
        })();
    }, [initCounting, vendorList]);

    const addSegments = (segments: Segment[]) => {
        const segmentItems = segments.map((it) => ({segment: it, limit: null}));
        const deduplicatedSegmentList = segmentItems.filter((it) => [...selectedSegments, ...selectedModelingSegments].every(({segment}) => segment.id !== it.segment.id));
        setSelectedSegments([...selectedSegments, ...deduplicatedSegmentList.filter(({segment}) => segment.data_type !== DataType.MODELING.name)]);
        setSelectedModelingSegments([...selectedModelingSegments, ...deduplicatedSegmentList.filter(({segment}) => segment.data_type === DataType.MODELING.name)]);
        setTimeout(() => {
            setHighlightedSegments([]);
        }, 800);
        setHighlightedSegments(deduplicatedSegmentList);
    };

    const changeLimitSegmentRow = (rowIndex: number, newLimit: number | null, isModeling?: boolean) => {
        if (isModeling) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments[rowIndex].limit = newLimit;
                return newSegments;
            });
        } else {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments[rowIndex].limit = newLimit;
                return newSegments;
            });
        }
    };

    const moveFirstPositionSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (isModeling) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                const segment = newSegments[rowIndex];
                newSegments.splice(rowIndex, 1);
                newSegments = [segment, ...newSegments];
                return newSegments;
            });
        } else {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                const segment = newSegments[rowIndex];
                newSegments.splice(rowIndex, 1);
                newSegments = [segment, ...newSegments];
                return newSegments;
            });
        }
    };

    const moveDownSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (isModeling && selectedModelingSegments.length > rowIndex + 1) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                const segmentRow = newSegments[rowIndex];
                newSegments[rowIndex] = newSegments[rowIndex + 1];
                newSegments[rowIndex + 1] = segmentRow;
                return newSegments;
            });
        } else if (!isModeling && selectedSegments.length > rowIndex + 1) {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                const segmentRow = newSegments[rowIndex];
                newSegments[rowIndex] = newSegments[rowIndex + 1];
                newSegments[rowIndex + 1] = segmentRow;
                return newSegments;
            });
        }
    };

    const moveUpSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (rowIndex > 0) {
            if (isModeling) {
                setSelectedModelingSegments((prevState) => {
                    let newSegments = [...prevState];
                    const segmentRow = newSegments[rowIndex];
                    newSegments[rowIndex] = newSegments[rowIndex - 1];
                    newSegments[rowIndex - 1] = segmentRow;
                    return newSegments;
                });
            } else {
                setSelectedSegments((prevState) => {
                    let newSegments = [...prevState];
                    const segmentRow = newSegments[rowIndex];
                    newSegments[rowIndex] = newSegments[rowIndex - 1];
                    newSegments[rowIndex - 1] = segmentRow;
                    return newSegments;
                });
            }
        }
    };

    const removeSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (isModeling) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments.splice(rowIndex, 1);
                return newSegments;
            });
        } else {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments.splice(rowIndex, 1);
                return newSegments;
            });
        }
    };

    const handleAddSegments = (result: SearchIdsResult) => {
        addSegments(result.matchedItems);
        setSegmentIdsSearchResult(result);
        setTimeout(() => setSegmentIdsSearchResult(undefined), 5000);
    };

    const handleChangeQuery = (query: string) => {
        const queryId = parseInt(query);
        if (!isNaN(queryId)) {
            const segment = segments.find(({id}) => id === queryId);
            setCurrentSegments(segment ? [segment] : []);
        } else {
            setCurrentSegments([...segments]);
        }
    };

    const handleSubmit = () => {
        let newCounting = new StorageFilter();
        newCounting.load(initCounting);

        let newSegmentFilter = new StorageSegmentFilter();
        if (segmentFilterType === SegmentFilterType.SEGMENT.name || segmentFilterType === SegmentFilterType.SEGMENT_WITH_CONSENT.name) {
            newSegmentFilter.segment = selectedSegments.map(({segment, limit}) => {
                const newSegment = new SegmentFilterSegment();
                newSegment.id = segment.id;
                newSegment.limit = limit;
                return newSegment;
            });
            newSegmentFilter.segment_modeling = selectedModelingSegments.map(({segment, limit}) => {
                const newSegment = new SegmentFilterSegment();
                newSegment.id = segment.id;
                newSegment.limit = limit;
                return newSegment;
            });
        }
        newCounting.segment_filter = newSegmentFilter;

        newCounting.consent_filter = [];
        if (segmentFilterType === SegmentFilterType.CONSENT.name || segmentFilterType === SegmentFilterType.SEGMENT_WITH_CONSENT.name) {
            if (selectedVendor) {
                const newConsentFilter = new StorageConsentFilter();
                newConsentFilter.purposes = selectedVendor.purposes;
                newConsentFilter.purposes_li = selectedVendor.legIntPurposes;
                newConsentFilter.extra_purposes = selectedVendor.extraPurposes;
                newConsentFilter.extra_purposes_li = selectedVendor.legIntExtraPurposes;
                newConsentFilter.vendor_id = selectedVendor.id;
                newCounting.consent_filter = [newConsentFilter];
            }
        }

        onSubmit(newCounting);
    };

    const hasEmptyFields = () => {
        const hasEmptySelectedSegments = !selectedSegments.length && !selectedModelingSegments.length;
        const hasEmptySelectedVendor = !selectedVendor;
        let hasEmptyFields = false;

        switch (segmentFilterType) {
            case SegmentFilterType.SEGMENT.name:
                hasEmptyFields = hasEmptySelectedSegments;
                break;
            case SegmentFilterType.CONSENT.name:
                hasEmptyFields = hasEmptySelectedVendor;
                break;
            case SegmentFilterType.SEGMENT_WITH_CONSENT.name:
                hasEmptyFields = hasEmptySelectedSegments || hasEmptySelectedVendor;
                break;
            default:
                break;
        }

        return hasEmptyFields;
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <Loadable loading={isLoading}>
                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                            <FieldBlock label={textProcessCounting("segments_filters.counting_type")}>
                                <RadioButtons
                                    id="currentSegmentFilterTypeName"
                                    value={segmentFilterType}
                                    options={SegmentFilterType.values().map((it) => ({value: it.name, label: textProcessCounting(`segments_filters_types.${it.name}`)}))}
                                    onChange={(value) => setProcessCounting({type: ModalProcessCountingActionType.UPDATE_SEGMENT_FILTER_TYPE, segmentFilterType: value as string})}
                                />
                            </FieldBlock>
                            {(segmentFilterType === SegmentFilterType.SEGMENT.name || segmentFilterType === SegmentFilterType.SEGMENT_WITH_CONSENT.name) &&
                                <div className="form-counting-filter-group">
                                    <div className="form-counting-filter-group-description">
                                        {textProcessCounting("segments_filters.filter_type_segment.search_description")}
                                    </div>
                                    <div className="form-counting-filter-group-config">
                                        <div className="form-counting-fields">
                                            <FormLayoutRows>
                                                <FieldBlock
                                                    label={textProcessCounting("segments_filters.filter_type_segment.search.label")}
                                                    actions={(
                                                        <IconTooltip
                                                            icon={Action.OPEN.icon}
                                                            text={textProcessCounting("segments_filters.filter_type_segment.search.audience_link")}
                                                            onClick={() => window.open(audienceHome, "_blank")}
                                                        />
                                                    )}
                                                >
                                                    <SearchAutocomplete
                                                        items={currentSegments.map((it) => {
                                                            return {
                                                                value: it,
                                                                searchField: `${it.query_name}`,
                                                                component: (
                                                                    <>
                                                                        <TagDataType type={DataType.getByName(it.data_type)} active/>
                                                                        <span>{it.name} ({it.id})</span>
                                                                        <Icon {...Action.ADD.icon}/>
                                                                    </>
                                                                )
                                                            };
                                                        })}
                                                        selectedItems={[...selectedSegments, ...selectedModelingSegments].map((it) => {
                                                            return {value: it, searchField: `${it.segment.query_name}`};
                                                        })}
                                                        onChange={handleChangeQuery}
                                                        onSelect={(item) => addSegments([item.value])}
                                                        placeholder={textProcessCounting("segments_filters.filter_type_segment.search.placeholder")}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textProcessCounting("segments_filters.filter_type_segment.add_list.label")}
                                                    tooltip={textProcessCounting("segments_filters.filter_type_segment.add_list.tooltip")}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                >
                                                    <SearchIds
                                                        placeholder={textProcessCounting("segments_filters.filter_type_segment.add_list.placeholder")}
                                                        items={segments}
                                                        selectedItems={[...selectedSegments, ...selectedModelingSegments].map((it) => it.segment)}
                                                        onSubmit={handleAddSegments}
                                                    />
                                                    {!!segmentIdsSearchResult?.matchedItems.length &&
                                                        <FormLayoutMessage
                                                            message={textProcessCounting("segment_ids_search_message.matched", {count: segmentIdsSearchResult?.matchedItems.length})}
                                                            severity={AlertSeverity.SUCCESS}
                                                        />
                                                    }
                                                    {!!segmentIdsSearchResult?.alreadySelectedIds.length &&
                                                        <FormLayoutMessage
                                                            message={textProcessCounting("segment_ids_search_message.already_selected", {count: segmentIdsSearchResult?.alreadySelectedIds.length, ids: segmentIdsSearchResult?.alreadySelectedIds.toString()})}
                                                            severity={AlertSeverity.WARNING}
                                                        />
                                                    }
                                                    {!!segmentIdsSearchResult?.unmatchedIds.length &&
                                                        <FormLayoutMessage
                                                            message={textProcessCounting("segment_ids_search_message.unmatched", {count: segmentIdsSearchResult?.unmatchedIds.length, ids: segmentIdsSearchResult?.unmatchedIds.toString()})}
                                                            severity={AlertSeverity.DANGER}
                                                        />
                                                    }
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </div>
                                        <div className="form-counting-fields">
                                            <div className="form-counting-list">
                                                {selectedSegments.length > 1 &&
                                                    <div className="form-counting-list-text" dangerouslySetInnerHTML={{__html: textProcessCounting("segments_filters.filter_type_segment.result_description")}}/>
                                                }
                                                {selectedSegments.length > 0 ?
                                                    <div className="form-counting-list-content">
                                                        {selectedSegments.map(({segment, limit}, index) => (
                                                            <TagSegment
                                                                key={segment.id}
                                                                segment={segment}
                                                                limit={limit}
                                                                isHighlighted={!!highlightedSegments?.find((it) => it.segment.id === segment.id)}
                                                                onChangeLimit={(newLimit) => changeLimitSegmentRow(index, newLimit)}
                                                                onMoveFirstPosition={() => moveFirstPositionSegmentRow(index)}
                                                                onMoveDown={() => moveDownSegmentRow(index)}
                                                                onMoveUp={() => moveUpSegmentRow(index)}
                                                                onRemove={() => removeSegmentRow(index)}
                                                            />
                                                        ))}
                                                    </div> :
                                                    <span className="form-counting-list-placeholder">{textProcessCounting("segments_filters.filter_type_segment.result_placeholder")}</span>
                                                }
                                            </div>
                                            {!!selectedModelingSegments.length &&
                                                <div className="form-counting-list">
                                                    <div className="form-counting-list-text" dangerouslySetInnerHTML={{__html: textProcessCounting("segments_filters.filter_type_segment.segments_modeling_warning")}}/>
                                                    <div className="form-counting-list-content">
                                                        {selectedModelingSegments.map(({segment, limit}, index) => (
                                                            <TagSegment
                                                                key={segment.id}
                                                                segment={segment}
                                                                limit={limit}
                                                                isHighlighted={!!highlightedSegments?.find((it) => it.segment.id === segment.id)}
                                                                onChangeLimit={(newLimit) => changeLimitSegmentRow(index, newLimit, true)}
                                                                onMoveFirstPosition={() => moveFirstPositionSegmentRow(index, true)}
                                                                onMoveDown={() => moveDownSegmentRow(index, true)}
                                                                onMoveUp={() => moveUpSegmentRow(index, true)}
                                                                onRemove={() => removeSegmentRow(index, true)}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {segmentFilterType === SegmentFilterType.SEGMENT_WITH_CONSENT.name &&
                                <div className="form-counting-filter-separator">
                                    <span>+</span>
                                </div>
                            }
                            {(segmentFilterType === SegmentFilterType.CONSENT.name || segmentFilterType === SegmentFilterType.SEGMENT_WITH_CONSENT.name) &&
                                <div className="form-counting-filter-group">
                                    <div className="form-counting-filter-group-description">
                                        {textProcessCounting("segments_filters.filter_type_consent.description")}
                                    </div>
                                    <div className="form-counting-filter-group-config">
                                        <div className="form-counting-fields">
                                            <FieldBlock label={textProcessCounting("segments_filters.filter_type_consent.search.label")}>
                                                <SelectAutocomplete
                                                    value={selectedVendor?.id}
                                                    options={vendorList.vendors.map((vendor) => ({label: `${vendor.id}. ${vendor.name}`, value: vendor.id, vendor}))}
                                                    onChange={(option) => setSelectedVendor(option?.vendor as Vendor)}
                                                    placeholder={textProcessCounting("segments_filters.filter_type_consent.search.placeholder")}
                                                    clearable
                                                />
                                            </FieldBlock>
                                        </div>
                                    </div>
                                </div>
                            }
                        </FormLayoutRows>
                    </Loadable>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={onGoBack ? ModalActionsAlignment.SPACE_BETWEEN : ModalActionsAlignment.RIGHT}>
                {onGoBack && <ButtonBack onClick={onGoBack}/>}
                <ButtonValidate size={ButtonSize.MEDIUM} onClick={handleSubmit} disabled={isLoading || hasEmptyFields()}/>
            </ModalActions>
        </>
    );
};

export default ModalProcessCountingSegmentsFilters;
